@keyframes scaleInCenter {
    0% {
        opacity: 0;
        -webkit-transform: scale(0);
        transform: scale(0);
    }

    100% {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}


@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes scale-ease-in {
    0% {
        opacity: 0;
        transform: scale(0)
    }

    50% {
        transform: scale(1.1)
    }

    100% {
        opacity: 1;
        transform: scale(1) perspective(1200px);
    }
}

@keyframes scale-fade-in {
    0% {
        opacity: 0;
        transform: scaleY(0);
    }

    50% {
        transform: scaleY(1.1);
    }

    100% {
        opacity: 1;
        transform: scaleY(1);
    }
}


.animate-scaleInCenter {
    animation: scaleInCenter cubic-bezier(.22, .58, .12, .98) .4s;
}

.animate-fade-in {
    animation: fade-in cubic-bezier(.22, .58, .12, .98) .3s;
}

.animate-fade-in-08 {
    animation: fade-in cubic-bezier(.22, .58, .12, .98) 0.8s;
}

.animate-scale-ease-in {
    -webkit-animation: scale-ease-in cubic-bezier(.22, .58, .12, .98) .4s;
    animation: scale-ease-in cubic-bezier(.22, .58, .12, .98) .4s;
}

.animate-scale-fade-in {
    -webkit-animation: scale-fade-in ease .6s;
    animation: scale-fade-in ease .6s;
}

.animate-text-waiting:after {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: url('../../images/loading.gif') no-repeat;
    background-size: 100%;
    content: '';
    vertical-align: middle;
    margin-left: 5px;
}