/* 头部 */
.padding-placeholder {
    padding-top: 100px;
}

.header {
    height: 100px;
    border-bottom: 1px solid #eee;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 100;
}

.header-logo-box,
.header-btn-box {
    height: 100px;
    line-height: 100px;
}

.header-btn-box {
    width: 290px;
}

.header-logo-box .logo {
    width: 100px;
    height: 72px;
}

.header-nav-list .nav {
    height: 100px;
    min-width: 100px;
    line-height: 100px;
    text-align: center;
    padding: 0 16px;
}

.header-btn-box .btn {
    border: 1px solid #63B504;
    border-radius: 4px;
    padding: 10px 20px;
    display: inline-block;
    line-height: 1;
}

.header-btn-box .avator {
    display: inline-block;
    margin: 0 24px;
}

.header-btn-box .avator img {
    width: 40px;
    height: 40px;
    line-height: 1;
    margin-bottom: 2px;
}

.header-btn-box .login-out {
    display: inline-block;
    margin-right: 24px;
}

.header-btn-box .message {
    display: inline-block;
}

.header-btn-box .message .red-pointer {
    border-radius: 50%;
    background-color: red;
    height: 6px;
    width: 6px;
    right: -6px;
    top: 42%;
    transform: translateY(-50%);
    z-index: 10;
}


.header-btn-box .message img,
.header-btn-box .login-out {
    width: 24px;
    height: 24px;
}

.header-btn-box .btn.login {
    background-color: #63B504;
    color: #fff;
}

.header-nav-dropdown-list {
    width: 160px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid #cccccc;
    border-radius: 4px;
    z-index: 100;
    transform-origin: 80% 0;
    display: none;
    padding: 10px 0;
    color: #333;
    font-weight: normal;
}

.header-nav-dropdown-list.tab-navs {
    top: 80px;
    right: 0;
}

.header-nav-dropdown-list.message-box {
    right: -16px;
    top: 90px;
    padding: 0 13px;
    width: 300px;
    border-radius: 4px;
    line-height: 1;
}

.header-nav-dropdown-list.user-box {
    right: -10px;
    top: 90px;
    padding: 10px 13px;
    width: 300px;
    border-radius: 4px;
    line-height: 1;
    cursor: auto;
}

.header-nav-a:hover .header-nav-dropdown-list,
.header-btn-box .message:hover .header-nav-dropdown-list,
.header-btn-box .avator:hover .header-nav-dropdown-list {
    display: block;
}

.header-nav-a .header-nav-dropdown-list .item:hover {
    color: #63B504;
}

.header-nav-dropdown-list .item {
    padding: 10px;
    line-height: 1;
}

.header-nav-dropdown-list .msg-title {
    height: 45px;
    line-height: 45px;
    border-bottom: 1px solid #EEE;
}

.header-nav-dropdown-list .msg-title:last-child {
    border-bottom: none;
}

.header-nav-dropdown-list .msg-item {
    padding: 20px 0 10px 0;
    border-bottom: 1px solid #EEE;
}


.header-nav-dropdown-list .triangle {
    top: -10px;
    right: 20px;
}

.triangle-y {
    border-left: 8px dashed transparent;
    border-right: 8px dashed transparent;
}


.header-nav-dropdown-list .triangle-top {
    border-bottom: 10px solid #fff;
    z-index: 2;
}

.header-nav-dropdown-list .triangle-top2 {
    border-bottom: 12px solid #ccc;
    z-index: 1;
    top: -13px;
}

/* 脚部 */
.footer {
    width: 100%;
    background-color: #373D44;
}

.footer .comWidth-center {
    padding: 78px 0;
}

.footer-company-title {
    line-height: 1.9;
}

.footer-company-content {
    margin-top: 29px;
}

.footer-company-content .item,
.footer-aboutus-content .item {
    line-height: 2.1;
}

.footer-wxcode {
    padding-top: 24px;
}

.footer-wxcode a {
    display: inline-block;
    margin-right: 40px;
    width: 130px;
}

.footer-wxcode a img {
    width: 130px;
    height: 130px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.footer-aboutus {
    border-left: 2px solid #4C5055;
    padding-left: 50px;
}

.footer-aboutus-title {
    margin-bottom: 29px;
}

.footer-a {
    color: rgba(255, 255, 255, .6)
}

.footer-link a {
    float: left;
    margin-right: 30px;
}

.footer-link a:first-child {
    color: #fff;
}

.footer-a a:hover {
    color: rgba(255, 255, 255, 1)
}

/* banner */
.home-banner {
    margin-bottom: 105px;
    background-color: #eee;
}

.home-banner .iSwiper-container,
.com-banner {
    height: 520px;
}

.home-banner .swipe-item-1 {
    background: url('../../images/home-banner-1.png') no-repeat;
    background-size: cover;
}

.home-banner .swipe-item-2 {
    background: url('../../images/home-banner-2.png') no-repeat;
    background-size: cover;
}

.home-banner .swipe-item-3 {
    background: url('../../images/home-banner-3.png') no-repeat;
    background-size: cover;
}

.home-banner .dot,
.case-banner .dot {
    width: 30px;
    height: 4px;
    background: rgba(255, 255, 255, .5);
    border-radius: 3px;
}

.home-banner .dot.active,
.case-banner .dot.active {
    width: 30px;
}

.home-banner .dots {
    bottom: 98px;
}


.home-banner .comWidth-center .bottom {
    width: 860px;
    height: 100px;
    display: inline-block;
    background: url('../../images/banner-1-bottom.png') no-repeat;
    background-size: cover;
    line-height: 100px;
    margin-top: 50px;
}

.home-banner .comWidth-center img {
    height: 368px;
    width: 308px;
    margin-right: 10px;
}

.home-banner .comWidth-center .inner .text {
    width: 620px;
    padding-top: 30px;
}


.home-banner .free-register {
    margin-top: 60px;
    width: 170px;
    height: 50px;
    background-color: #fff;
    color: #63B504;
    border-radius: 4px;
    line-height: 50px;
    text-align: center;
    display: block;
}

/* 免费创建 */
.home-banner .free-build {
    display: block;
    background: #89CE41;
    color: #fff;
    width: 110px;
    height: 44px;
    line-height: 44px;
    font-size: 18px;
    margin-top: 40px;
    border-radius: 4px;
    text-align: center;
}


.home-banner-bottom {
    width: 1200px;
    height: 200px;
    background-color: #fff;
    z-index: 10;
    border-radius: 8px;
    left: 50%;
    margin-left: -600px;
    bottom: -110px;
    box-shadow: 0 1px 40px 0 rgba(0, 0, 0, .1);
}

.home-banner-bottom .adv {
    float: left;
    width: 25%;
    height: 200px;
    padding: 50px 0;
}

.home-banner-bottom .adv img {
    display: block;
    width: 47px;
    height: 47px;
    margin: 0 auto;
    margin-bottom: 20px;
}

.home-changeFuture {
    margin-bottom: 80px;
}

.home-changeFuture-adv-list {
    margin-top: 57px;
    margin-right: -40px;
}

.home-changeFuture-adv-list .item {
    float: left;
    width: 25%;
    padding-right: 40px;
}

.home-changeFuture-adv-list .item .inner {
    width: 270px;
    height: 360px;
    background: rgba(247, 247, 247, 1);
    padding-top: 46px;
}

.home-changeFuture-adv-list .item .inner img {
    display: block;
    height: 230px;
    width: 226px;
    margin: 0 auto;
    margin-bottom: 0;
}

.home-allaroud {
    min-height: 830px;
    background: rgba(247, 247, 247, 1);
}

.home-allaroud-box,
.home-scene-box {
    padding: 80px 0;
}

.home-allaroud-box .title,
.home-scene-box .title,
.home-dynamic-box .title,
.xiaowei-contact-box .title,
.home-case-box .title,
.com-contact-box .title,
.intro-product-box .title,
.intro-choose-box .title {
    line-height: 2;
}

.home-allaroud-box .content .show {
    margin-top: 54px;
}

.home-allaroud-box .content .show .img {
    margin: 0 auto;
    display: block;
    width: 372px;
}

.home-allaroud-box .content .show img {
    max-width: 100%;
    display: block;
}

.home-allaroud-box .content .list {
    margin-top: 43px;
}

.home-allaroud-box .content .list .item {
    margin-bottom: 5px;
}

.home-allaroud-box .content .list .item:last-child {
    margin-bottom: 0;
}

.home-allaroud-box .content .list .item img {
    height: 30px;
    width: 30px;
    display: block;
    margin: 20px 15px;
    float: left;
}

.home-allaroud-box .content .list .item .tab {
    display: inline-block;
    padding-right: 5px;
    border-right: 1px solid #666;
    margin-bottom: 6px;
}

.home-allaroud-box .content .list .item .tab:last-child {
    border-right: none;
}

.home-allaroud-box .content .show .mask {
    left: 148px;
    top: 49px;
    width: 180px;
    height: 318px;
}

.home-allaroud-box .iSwiper-container {
    height: 100%;
}

.home-scene-box {
    background: url('../../images/mask.png') no-repeat;
    background-size: cover;
    height: 619px;
}

.home-scene-box .comWidth-center {
    margin-top: 37px;
}

.home-scene-box .list {
    margin-right: -27px;
}

.home-scene-box .list .item {
    padding-right: 27px;
    width: 25%;
    margin-bottom: 26px;
    float: left;
    cursor: pointer;
}

.home-scene-box .list .inner {
    background-size: cover;
    height: 160px;
}

.home-scene-box .list .inner .mask img {
    height: 42px;
    width: 42px;
}

.home-scene-box .list .inner .mask {
    transition: all .35s ease-in-out;
}

.home-scene-box-scene1 {
    background: url('../../images/scene1.png') no-repeat;
}


.home-scene-box-scene2 {
    background: url('../../images/scene2.png') no-repeat;
}

.home-scene-box-scene3 {
    background: url('../../images/scene3.png') no-repeat;
}

.home-scene-box-scene4 {
    background: url('../../images/scene4.png') no-repeat;
}

.home-scene-box-scene5 {
    background: url('../../images/scene5.png') no-repeat;
}

.home-scene-box-scene6 {
    background: url('../../images/scene6.png') no-repeat;
}

.home-scene-box-scene7 {
    background: url('../../images/scene7.png') no-repeat;
}

.home-scene-box-scene8 {
    background: url('../../images/scene8.png') no-repeat;
}

.home-scene-box .list .inner .mask {
    background-color: rgba(0, 0, 0, .4);
}

.home-scene-box .list .inner .mask .icon {
    display: none;
}

.home-scene-box .list .home-scene-box-scene:hover .mask .icon {
    display: block;
}

/* mask color */
.home-scene-box .list .home-scene-box-scene1:hover .mask {
    background-color: rgba(60, 163, 228, .5);
}

.home-scene-box .list .home-scene-box-scene2:hover .mask {
    background: rgba(115, 141, 255, .5);
}

.home-scene-box .list .home-scene-box-scene3:hover .mask {
    background: rgba(255, 88, 104, .5);
}

.home-scene-box .list .home-scene-box-scene4:hover .mask {
    background: rgba(255, 183, 43, .5);
}

.home-scene-box .list .home-scene-box-scene5:hover .mask {
    background: rgba(118, 255, 182, .5);
}

.home-scene-box .list .home-scene-box-scene6:hover .mask {
    background: rgba(113, 237, 255, .5);
}

.home-scene-box .list .home-scene-box-scene7:hover .mask {
    background: rgba(195, 79, 255, .5);
}

.home-scene-box .list .home-scene-box-scene8:hover .mask {
    background: rgba(71, 255, 75, .5);
}

.home-allaroud-bottom {
    height: 94px;
    line-height: 94px;
}

.home-allaroud-bottom .btn-know-more {
    width: 130px;
    height: 40px;
    border: 1px solid rgba(99, 181, 4, 1);
    border-radius: 4px;
    line-height: 40px;
    text-align: center;
    display: inline-block;
    cursor: pointer;
}

.home-dynamic {
    min-height: 1288px;
    background: rgba(255, 255, 255, 1);
    padding: 80px 0;
}

.tab-list-x .item {
    float: left;
    padding: 20px 0;
    margin-right: 68px;
    min-width: 65px;
    text-align: center;
    cursor: pointer;
}

.tab-list-x {
    border-bottom: 1px solid #E6E6E6;
}

.tab-list-x .item:last-child {
    margin-right: 0;
}

.tab-list-x .item.active {
    color: #63B504;
    border-bottom: 4px solid #63B504;
    font-weight: bolder;
}


/* 资讯列表的样式 maybe common */
.news-content-item img {
    float: left;
    margin-right: 30px;
    width: 300px;
    height: 180px;
}

.news-content-item {
    border-bottom: 1px solid #E6E6E6;
    padding-bottom: 40px;
    padding-top: 30px;
    display: block;
}


.news-content-item .right {
    height: 100%;
    width: 72%;
}

.news-content-item .right .title {
    line-height: 1.7;
    margin-bottom: 3px;
}

.news-content-item .right .desc {
    line-height: 1.6;
    height: 50px;
}

.news-content-item .label-list {
    margin-top: 37px;
}

.news-content-item .label-list .label {
    border: 1px solid rgba(230, 230, 230, 1);
    border-radius: 4px;
    color: #999;
    padding: 10px 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    display: inline-block;
}

/* 联系小未 */
.xiaowei-contact {
    min-height: 462px;
    background: url('../../images/contact-bg.png') no-repeat;
    background-size: cover;
}

.xiaowei-contact {
    padding: 80px 0;
}

.xiaowei-contact-form {
    margin-top: 37px;
    margin-left: auto;
    margin-right: auto;
    width: 360px;
}

.xiaowei-contact-form .form-group {
    margin-bottom: 30px;
    line-height: 44px;
}

.xiaowei-contact-form .form-group label {
    font-size: 18px;
    font-weight: bolder;
    margin-right: 15px;
}

.xiaowei-contact-form .form-group input {
    line-height: 1;
    display: inline-block;
    width: 300px;
    height: 44px;
    background: rgba(255, 255, 255, 1);
    border-radius: 6px;
    padding: 10px;
    border: 1px solid rgba(230, 230, 230, 1);
}

.xiaowei-contact-form .form-group input:focus,
.xiaowei-contact-form .form-group textarea:focus {
    border: 1px solid rgba(99, 181, 4, 1);
}

.xiaowei-contact-form .form-group textarea {
    width: 300px;
    height: 130px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(230, 230, 230, 1);
    border-radius: 6px;
    padding: 10px;
    line-height: 1.4;
}

.xiaowei-contact-form .btn-submit {
    width: 100px;
    height: 40px;
    background: rgba(99, 181, 4, 1);
    border-radius: 4px;
    color: #fff;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
    line-height: 40px;
}

/* 小未案例 */
.home-case {
    padding-top: 80px;
    padding-bottom: 50px;
    background-color: #f7f7f7;
}

.home-case-box .anli-show {
    margin-top: 100px;
    height: 666px;
    padding-top: 90px;
    margin-top: 20px;
}

.home-case-box .phone-stage {
    width: 357px;
    height: 642px;
    left: 50%;
    transform: translateX(-50%);
    top: 2px;
    z-index: 1;
    margin-left: 1px;
}

.home-case-box .anli-show-container {
    z-index: 2;
}

.home-case-box .anli-img {
    display: block;
    width: 243px;
    height: 448px;
    margin: 0 auto;
}

.home-case-box .anli-show-container .swiper-slide {
    transform: scale(.8);
    transition: all .5s cubic-bezier(.4, 0, 0, 1);
    opacity: .6;
}

.home-case-box .anli-show-container .swiper-slide-active {
    transform: scale(1) !important;
    opacity: 1;
}

#anliSwiper {
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

.home-case-box .swiper-button-next,
.home-case-box .swiper-button-prev {
    background-size: cover;
}

.home-case-box .swiper-button-next {
    background-image: url('../images/btn-right.png');
}

.home-case-box .swiper-button-prev {
    background-image: url('../images/btn-left.png');
}

/* 案例页样式 */
.case-banner {
    background: #eee;
}

.com-banner-desc {
    top: 50%;
    transform: translateY(-50%);
}

.case-banner .iSwiper-container {
    height: 520px;
}

.case-banner .btn-scan {
    color: #63B504;
    background: #fff url('../images/anli-banner/border.png') no-repeat;
    background-size: cover;
}

.case-banner .btn-scan:hover {
    background-color: transparent;
    color: #fff;
}


.case-banner .left {
    width: 470px;
}

.case-banner .wxcode {
    right: -130%;
    width: 160px;
    height: 160px;
    z-index: 11;
    display: block;
    top: 50%;
    margin-top: -65px;
    display: none;
}

.case-banner .phone {
    right: 143px;
    top: 14px;
    width: 181px;
    height: 325px;
    z-index: 10
}

.case-banner .phone-stage {
    display: block;
    width: 364px;
    height: 438px;
    right: 0;
    bottom: -114px;
}

.case-banner .bg {
    left: 0;
    top: 0;
    width: 100%;
    display: block;
    height: 100%;
}

.case-banner .btn-scan:hover .wxcode {
    display: block;
}

.com-banner-desc .title {
    font-size: 66px;
}

.com-banner .btn-try {
    width: 140px;
    height: 50px;
    border-radius: 4px;
    line-height: 50px;
    border: 1px solid rgba(255, 255, 255, 1);
    display: block;
    font-weight: normal;
}

.case-tabs-box {
    margin-right: -78px;
}

.case-tabs {
    min-height: 220px;
}

.case-tabs-box-tab {
    margin-right: 61px;
    margin-bottom: 30px;
    width: 80px;
    cursor: pointer;
}

.case-tabs-box-tab img {
    display: block;
    height: 40px;
    width: 40px;
    margin: 0 auto;
    margin-bottom: 14px;
}

.case-list {
    padding: 50px 0;
}

.case-list-box {
    margin-right: -20px;
}

.case-list-box .item {
    padding-right: 20px;
    margin-bottom: 30px;
    width: 20%;
    float: left;
}

.case-list-box .item .inner {
    border: 1px solid rgba(242, 242, 242, 1);
    height: 284px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 4px;
}

.case-list-box .item .inner .img-wrapper {
    display: block;
    margin-bottom: 20px;
}

.case-list-box .item .inner .img-wrapper img {
    width: 223px;
    height: 223px;
    border-bottom: 1px solid #eee;
    display: block;
}

.com-pagination {
    height: 34px;
    line-height: 34px;
}

.com-pagination-wrapper .btn {
    cursor: pointer;
    height: 34px;
    padding: 0 15px;
    margin-right: 14px;
    float: left;
    border: 1px solid rgba(242, 242, 242, 1);
}

.com-pagination-wrapper .btn.active {
    background-color: #63B504;
    color: #fff;
}

.com-pagination-wrapper .del {
    margin-left: 6px;
    height: 34px;
    float: left;
    padding-right: 27px;
}

.com-contact {
    padding: 80px 0;
}

.com-contact-ways {
    margin-right: -90px;
}

.com-contact-ways .way {
    cursor: pointer;
    width: 33.333%;
    padding-right: 90px;
    float: left;
}

.com-contact-ways .way .inner {
    height: 340px;
    background: rgba(255, 255, 255, 1);
    border-radius: 6px;
    padding-top: 70px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, .1);
}

.com-contact-ways .way .inner img {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    width: 144px;
    height: 144px;
    display: block;
}

.case-list-box .wxcode-wrapper {
    transform-origin: left bottom;
    transition: all .6s cubic-bezier(.22, .58, .12, .98);
    z-index: 1;
    transform: scaleY(0);
}

.case-list-box .wxcode-wrapper .wxcode {
    width: 180px;
    height: 180px;
}

.case-list-box .inner:hover .wxcode-wrapper {
    transform: scaleY(1)
}

.case-list-box .inner:hover {
    box-shadow: 0px 1px 10px rgba(0, 0, 0, .1);
    transition: all .1s cubic-bezier(.22, .58, .12, .98) .8s;
}

.com-contact-box .wxcode-wrapper .wxcode {
    width: 220px;
    height: 220px;
}

.com-contact-ways .wxcode-wrapper {
    opacity: 0;
    transform: scale(1.2);
    transition: all 1s cubic-bezier(.22, .58, .12, .98);
    z-index: 1;
}

.com-contact-ways .inner:hover .wxcode-wrapper {
    opacity: 1;
    transform: scale(1);
    border-radius: 50%;
}


/* 功能介绍 */

.intro-banner {
    background: url('../../images/intro-banner.png') no-repeat;
    background-size: cover;
}

.intro-banner .left {
    width: 630px;
    padding-top: 60px;
}

.intro-banner .inset {
    width: 396px;
    display: block;
}

.intro-banner .left p:first-child {
    margin-bottom: 45px;
    font-size: 56px;
}

.intro-banner .left p:nth-child(2) {
    margin-bottom: 60px;
    font-size: 38px;
}

.intro-product {
    padding: 80px 0;
    height: 684px;
    background: url('../../images/intro-product-bg.png') no-repeat;
    background-size: cover;
}

.intro-product-list {
    margin-right: -29px;
}

.intro-product-list .item {
    width: 25%;
    padding-right: 30px;
    float: left;
}

.intro-product-list .item .inner {
    height: 410px;
    padding: 28px 30px;
    background: url('../../images/intro-product-bg2.png') no-repeat;
    background-size: cover;
}

.intro-product-list .item .inner:hover {
    background-image: url('../../images/intro-product-bg2-active.png')
}

.intro-product-list .item .inner .top img {
    margin: 7px auto 17px auto;
    height: 44px;
    width: 44px;
    display: block;
}

.intro-product-list .item .inner .bottom {
    line-height: 1.5;
    margin-top: 74px;
}

.intro-choose {
    padding: 80px 0;
    height: 490px;
}

.intro-choose-reasons {
    margin-right: -30px;
}

.intro-choose-reasons .reason {
    width: 20%;
    padding-right: 30px;
    float: left;
}

.intro-choose-reasons .reason .inner {
    height: 216px;
    background: rgba(247, 247, 247, 1);
    border-radius: 4px;
    padding: 24px 20px;
    cursor: pointer;
}

.intro-choose-reasons .reason .inner img {
    display: block;
    width: 110px;
    height: 110px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
}

/* 小未培训视频列表 */
.tvl-banner {
    height: 200px;
    background: url('../../images/pxVideoList-banner-1.png') no-repeat;
    background-size: cover;
}

.tvl-banner .x-y-c {
    font-size: 48px;
}

.tvl-filter {
    border-bottom: 1px solid #E6E6E6;
}

.tvl-filter .triangle {
    display: block;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 1px;
}

.tvl-filter .triangle-top.active {
    border-bottom-color: #666;
}

.tvl-filter .triangle-bottom.active {
    border-bottom-color: #666;
}

.tvl-filter .tab-list-x {
    border: none;
}

.tvl-filter .filter-list {
    line-height: 63px;
}

.tvl-list {
    margin-right: -21px;
}

.tvl-list .item {
    padding-right: 21px;
    margin-bottom: 30px;
    float: left;
    width: 33.33%;
}

.tvl-list .item .inner {
    height: 300px;
}

.tvl-list .item .mask {
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(242, 242, 242, 1);
    border-radius: 4px;
    cursor: pointer;
    transition: all .35s ease;
}

.tvl-list .item .mask:hover {
    box-shadow: 0px 3px 30px rgba(0, 0, 0, .2);
    transform: translateY(-15px);
}

.tvl-list .item .mask:hover .title {
    color: #63B504;
}

.tvl-list .item .mask img {
    height: 192px;
    display: block;
    width: 100%;
}

.tvl-list .item .mask .title {
    line-height: 1.7;
    transition: all .35s cubic-bezier(.4, 0, 0, 1);
    min-height: 60px;
}

/* 渠道加盟 */
.join-banner {
    background: url('../../images/join-banner-1.png') no-repeat;
    background-size: cover;
}

.join-banner .modal {
    width: 340px;
    height: 340px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    padding: 30px;
}


.join-banner .modal .title span {
    display: inline-block;
    width: 12px;
    height: 4px;
    background-color: #333;
    vertical-align: middle
}

.join-banner .btn-fake {
    display: inline-block;
    width: 500px;
    height: 68px;
    background: rgba(99, 181, 4, 1);
    border-radius: 34px;
    line-height: 68px;
    margin-top: 16px;
}

.join-banner .xiaowei-contact-form {
    width: 280px;
}

.join-banner .xiaowei-contact-form input {
    width: 220px;
}

.join-help {
    height: 490px;
}

.join-help-plans {
    margin-right: -30px;
}

.join-help-plans .plan {
    width: 20%;
    padding-right: 30px;
    float: left;
}

.join-help-plans .plan .inner {
    height: 216px;
    background: rgba(247, 247, 247, 1);
    border-radius: 4px;
    padding: 24px;
}

.join-help-plans .plan .inner img {
    margin: 0 auto;
    margin-bottom: 8px;
    height: 110px;
    width: 110px;
    display: block;
}

.join-agent {
    background: url('../../images/join-agent-bg.png') no-repeat;
    background-size: cover;
}

.join-agent-levels {
    margin-right: -30px;
}

.join-agent-levels .level {
    width: 25%;
    padding-right: 30px;
    float: left;
}

.join-agent-levels .level .inner {
    height: 278px;
    padding: 40px;
    background-color: #fff;
}

.join-agent-levels .level .inner img {
    width: 90px;
    height: 124px;
    margin: 0 auto;
    margin-top: 16px;
    margin-bottom: 40px;
    display: block;
}

.join-agent-levels .level .inner .offset {
    margin-bottom: 3px;
    margin-top: -10px;
}


.join-desc {
    height: 563px;
}

.join-desc-persons .person {
    width: 33.33%;
    text-align: center;
    float: left;
}

.join-desc-persons .person .text {
    width: 302px;
    height: 226px;
    margin: 0 auto;
    margin-bottom: 40px;
    background: url('../../images/join-chat.png') no-repeat;
    background-size: cover;
    padding: 32px;
}

.join-desc-persons .person .text .content {
    line-height: 1.8;
    margin-top: 3px;
}

.join-flow {
    height: 390px;
    background: url('../../images/join-flow-bg.png') no-repeat;
    background-size: cover;
}

.join-flow-list {
    padding-left: 172px;
}

.join-flow-list .item {
    float: left;
    background: url('../../images/join-flow-item-bg.png') no-repeat;
    background-size: cover;
    width: 116px;
    height: 116px;
    line-height: 116px;
    text-align: center;
}

.join-flow-list .arrow {
    height: 116px;
    line-height: 116px;
    float: left;
}

.join-flow-list .arrow .triangle-right {
    border-left: 10px solid #fff;
    vertical-align: middle;
}

.join-flow-list .arrow .triangle-x {
    border-bottom: 8px dashed transparent;
    border-top: 8px dashed transparent;
}

.join.com-contact .com-contact-ways {
    padding-left: 214px;
}

.join.com-contact .com-contact-ways .way {
    width: 430px;
}

/* 关于我们--新闻动态 */
.abns-banner {
    background: url('../../images/abns-banner-bg.png') no-repeat;
    background-size: cover;
}

.com-news-left {
    width: 869px;
}

.com-breadcrumb {
    height: 45px;
    line-height: 45px;
}

.com-breadcrumb .arrow {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-top: 1px solid #666;
    border-right: 1px solid #666;
    transform: rotate(45deg);
    margin: 0 10px;
    vertical-align: middle;
}

.abns .news-content-item .right {
    width: 60%;
}

.abns .news-content-item {
    margin-top: -15px;
}

.com-news-right {
    padding-top: 167px;
}

.com-news-right .img-register {
    display: block;
    width: 270px;
    height: 250px;
}

.com-news-right-read .tabs .item {
    width: 50%;
    height: 40px;
    background: rgba(228, 228, 228, 1);
    float: left;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
}

.com-news-right-read .tabs .item.active,
.com-news-right-read-list .rank.active {
    background-color: #63B504;
    color: #fff;
}

.com-news-right-read .tabs .item-right {
    border-radius: 0px 4px 4px 0px;
}

.com-news-right-read .tabs .item-left {
    border-radius: 4px 0px 0px 4px;
}

.com-news-right-read-list a {
    display: block;
    margin-bottom: 14px;
}

.com-news-right-read-list a li {
    line-height: 18px;
}

.com-news-right-read-list .rank {
    width: 18px;
    height: 18px;
    border-radius: 2px;
    text-align: center;
    background-color: #C8C7C7;
    color: #fff;
    font-size: 12px;
}

.com-news-right-read-list .title {
    width: 190px;
}

.com-news-right-labels {
    margin-top: 26px;
    padding: 20px;
    padding-bottom: 0;
    width: 270px;
    /* min-height: 290px; */
    border: 1px solid rgba(226, 226, 226, 1);
    border-radius: 4px;
}

.com-news-right-labels .list .item {
    float: left;
    margin-right: 18px;
    margin-bottom: 30px;
}

/* 关于我们--公司简介 */
.abcy-banner {
    background: url('../../images/abcy-banner-bg.png') no-repeat;
    background-size: cover;
}

.abcy-brief .content {
    line-height: 1.77;
    text-align: left;
    margin-top: 33px;
    padding-bottom: 73px;
}

.abcy-brief .content p:last-child {
    margin-top: 26px;
}

.abcy-milestone {
    height: 936px;
    background: url('../../images/abcy-milestone-bg.png') no-repeat;
    background-size: cover;
}

.abcy-milestone-wrapper {
    height: 686px;
}

.abcy-milestone-wrapper .canter {
    width: 32px;
    left: 50%;
    transform: translateX(-50%);
}

.abcy-milestone-wrapper .canter .arrow {
    border-radius: 50%;
    width: 32px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border: 1px solid #fff;
    vertical-align: middle;
}

.abcy-milestone-wrapper .canter .arrow:after {
    content: '';
    display: inline-block;
    height: 8px;
    width: 8px;
    border-top: 1px solid #fff;
    border-right: 1px solid #fff;
    vertical-align: middle;
}

.abcy-milestone-wrapper .canter .arrow-top:after {
    transform: rotate(-45deg);
}

.abcy-milestone-wrapper .canter .arrow-bottom:after {
    transform: rotate(135deg);
    margin-top: -8px;
}

.abcy-milestone-wrapper .canter .vertical-line {
    margin: 0 auto;
    width: 1px;
    height: 582px;
    background-color: #fff;
}

.abcy-milestone-wrapper .canter .point {
    left: 50%;
    transform: translateX(-50%);
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.abcy-milestone-wrapper .canter .point:after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.abcy-milestone-wrapper .canter .point1 {
    top: 50px
}

.abcy-milestone-wrapper .canter .point2 {
    top: 115px
}

.abcy-milestone-wrapper .canter .point3 {
    top: 180px
}

.abcy-milestone-wrapper .canter .point4 {
    top: 245px
}

.abcy-milestone-wrapper .canter .point5 {
    top: 310px
}

.abcy-milestone-wrapper .canter .point6 {
    top: 375px
}

.abcy-milestone-wrapper .canter .point7 {
    top: 440px
}

.abcy-milestone-wrapper .canter .point8 {
    top: 505px
}

.abcy-milestone-wrapper .popup {
    width: 250px;
    min-height: 80px;
    background: rgba(255, 255, 255, 1);
    border-radius: 4px;
    padding: 20px;
    top: -20px;
}

.abcy-milestone-wrapper .popup p:first-child {
    font-weight: bolder;
}

.abcy-milestone-wrapper .popup-left {
    right: 47px;
}

.abcy-milestone-wrapper .popup-right {
    left: 47px;
}

.abcy-milestone-wrapper .popup-right .triangle-left {
    border-right: 12px solid #fff;
    left: -10px;
    top: 17px;
}

.abcy-milestone-wrapper .popup-left .triangle-right {
    border-left: 12px solid #fff;
    right: -10px;
    top: 17px;
}

.abcy-milestone-wrapper .triangle-x {
    border-bottom: 10px dashed transparent;
    border-top: 10px dashed transparent;
}

.abcy-team-ptos {
    margin-right: -42px;
}

.abcy-team-ptos .pto {
    float: left;
    width: 33.33%;
    padding-right: 42px;
    margin-bottom: 40px;
}

.abcy-team-ptos .pto .inner {
    height: 372px;
    border-radius: 6px;
}

.abcy-team-ptos .pto .inner img {
    width: 100%;
    display: block;
    height: 372px;
}

/* 新闻动态-详情页 */
.nsct .comWidth-center {
    padding-top: 26px;
}

.nsct-title {
    margin-top: 35px;
    padding-bottom: 30px;
    border-bottom: 1px solid #eee;
}

.nsct-content {
    padding: 22px 0;
    min-height: 450px;
    line-height: 1.9;
}

.nsct-rel-news .item {
    width: 50%;
}

.nsct-rel-news .item.fl {
    padding-right: 65px;
}

.nsct-rel-news .item.fr {
    padding-left: 65px;
}

.nsct-rel-labels {
    line-height: 40px;
}

.nsct-rel-labels .item {
    width: 100px;
    height: 40px;
    border: 1px solid rgba(230, 230, 230, 1);
    border-radius: 4px;
    text-align: center;
    float: left;
    margin: 0 15px;
    margin-bottom: 10px;
}

.nsct-rel-read {
    margin-top: 50px;
    border-radius: 4px;
    border: 1px solid rgba(226, 226, 226, 1);
    overflow: hidden;
}

.nsct-rel-read img {
    display: block;
    height: 150px;
    width: 100%;
}

.nsct-rel-read-bottom {
    padding: 30px 20px;
}

.nsct-rel-read-bottom .content {
    margin-top: 30px;
}

.nsct-rel-read-bottom .content ul {
    width: 50%;
    border-right: 1px solid #eee;
}


.nsct-rel-read-bottom .content ul:last-child {
    border-right: none;
}

.nsct-rel-read-bottom .content ul a {
    margin-bottom: 20px;
    display: block;
}

.nsct-rel-read-bottom .content ul a:last-child {
    margin-bottom: 0;
}

.nsct-rel-read-bottom .content .title {
    width: 237px;
}

.nsct-rel-read-bottom .content .date {
    padding-left: 20px;
    color: #888;
}

/* 小未百科 -- 产品帮助 */
.pthp-banner {
    background: url('../../images/product-help-banner-bg.png') no-repeat;
    background-size: cover;
}

.pthp-banner .wrapper {
    line-height: 66px;
}

.pthp-banner .search-box {
    width: 900px;
    height: 66px;
    background: rgba(0, 0, 0, .2);
    border-radius: 33px;
    padding: 0 30px;
}

.pthp-banner .modal {
    width: 61%;
}

.pthp-banner .search-box input {
    height: 45px;
    font-size: 18px;
    color: #fff;
    background-color: transparent;
    padding: 10px 0;
    width: 93%;
    display: inline-block;
}

.pthp-banner .search-box input::placeholder {
    color: #fff;
}

.pthp-banner .search-box .btn-search {
    width: 30px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -6px;
}

.pthp-read-list {
    margin-right: -30px;
}

.pthp-read-list .item {
    width: 33.33%;
    padding-right: 30px;
    margin-bottom: 40px;
    float: left;
}

.pthp-read-list .item .inner {
    height: 170px;
    border: 1px solid rgba(99, 181, 4, 1);
    border-radius: 6px;
    cursor: pointer;
}

.pthp-read-list .item .inner .mask {
    width: 100%;
    text-align: center;
}

.pthp-read-list .item .inner:hover .mask {
    color: #63B504;
}

.pthp-read-list .item .inner:hover .mask p:last-child {
    color: #63B504;
}

.pthp-problem {
    height: 534px;
    background: url('../images/hot-problem-bg.png') no-repeat;
    background-size: cover;
}

.pthp-problem-list {
    height: 260px;
    border: 1px solid rgba(204, 204, 204, 1);
    border-radius: 4px;
}

.pthp-problem-list .item {
    float: left;
    height: 260px;
    width: 25%;
    padding: 50px 0;
    text-align: center;
}

.pthp-problem-list .item ul a {
    margin-bottom: 13px;
    display: block;
}

.pthp-problem-list .item ul a li {
    padding: 0 20px;
}

.pthp-problem-list .item ul a:last-child {
    margin-bottom: 0;
}

.pthp-problem-list .item .v-line {
    right: 0;
    top: 50%;
    width: 1px;
    height: 100px;
    background: rgba(204, 204, 204, 1);
    margin-top: -50px;
}

.text-decorate a:hover {
    text-decoration: underline;
}

.pthp-update-list {
    height: 648px;
    border: 1px solid rgba(230, 230, 230, 1);
    border-radius: 4px;
    padding: 40px;
}

.pthp-update-list .com-news-right-read-list .title {
    width: 85%;
    font-size: 22px;
    color: #666;
}

.pthp-update-list .com-news-right-read-list a:hover .title {
    color: #63B504;
}

.pthp-update-list .com-news-right-read-list a {
    margin-bottom: 38px;
}

.pthp-update-list .com-news-right-read-list a:last-child {
    margin-bottom: 0;
}

.pthp-update-list .com-news-right-read-list .fr {
    font-size: 16px;
    color: #999;
}

.pthp-update-list .com-news-right-read-list a li {
    line-height: 22px;
}

.pthp-update-list .com-news-right-read-list .rank {
    width: 22px;
    height: 22px;
}

.pthp .xiaowei-contact {
    min-height: 314px;
}

/* 小未帮助中心-内页 */
.help-wrapper {
    border-radius: 4px;
}

.help-left {
    width: 910px;
}

.help-left-tabs {
    width: 210px;
    line-height: 80px;
    min-height: 746px;
}

.help-left-tabs .tab {
    height: 80px;
    padding: 0 20px;
    border-bottom: 1px solid #F2F2F2;
    cursor: pointer;
}

.help-left-tabs .tab .triangle {
    right: -10px;
    top: 50%;
    transform: translateY(-50%);
    border-left: 10px solid #63B504;
    display: none;
    z-index: 10;
}

.help-left-tabs .tab.active {
    background-color: #63B504;
    color: #fff;
}

.help-left-tabs .tab.active .triangle {
    display: block;
}

.help-left-tabs .triangle-x {
    border-bottom: 8px dashed transparent;
    border-top: 8px dashed transparent;
}

.help .gray-bg {
    background: #f7f7f7;
}

.help-left-content {
    min-height: 746px;
    width: 700px;
    padding: 0 36px;
    padding-bottom: 80px;
    border-left: 1px solid #F2F2F2;
}

.help-left-content-list .item {
    padding: 30px 0 23px 0;
    border-bottom: 1px solid #f2f2f2;
    min-height: 142px;
}

.help-left-content-list .item .desc {
    line-height: 1.8;
    margin-top: 12px;
}

.help-left-content .mask {
    padding: 0 36px;
}

.help-left-content .com-breadcrumb {
    border-bottom: 1px solid #f2f2f2;
    height: 53px;
    line-height: 53px;
}

.help-left-content .article .text {
    line-height: 1.8;
    margin-top: 24px;
}

.help-left-content .article img {
    max-width: 100%;
    display: block;
    cursor: pointer;
}

.help-right-adv {
    width: 270px;
    height: 405px;
}

.help-right-adv .wxcode {
    left: 50%;
    width: 110px;
    height: 110px;
    transform: translateX(-50%);
    top: 260px;
    z-index: 10;
}

.help-right-question .title_ {
    padding: 0 20px;
    height: 54px;
    line-height: 54px;
    border-bottom: 1px solid #f2f2f2;
}

.help-right-question .com-news-right-read-list .title {
    width: 190px;
}

.help-right-question .com-news-right-read-list a:last-child {
    margin-bottom: 0;
}

.help-right-question .com-news-right-read-list a .fr:after {
    content: '';
    display: inline-block;
    width: 6px;
    height: 6px;
    border-top: 1px solid #999;
    border-right: 1px solid #999;
    transform: rotate(45deg);
    vertical-align: middle;
}


/* 视频详情页 */
.video-details-container {
    width: 100%;
    height: 100%;
    background-color: #3f3f3f;
    padding-bottom: 80px;
}

.video-details-container .video-content {
    width: 1200px;
    margin: 0 auto;
    padding-top: 40px;
}

.video-details-container .video-content .video-title {
    color: #fff;
}

.video-details-container .video-content .video-title>h1 {
    font-size: 18px;
    font-weight: normal;
    padding-bottom: 12px;
}

.video-details-container .video-content .video-title>p {
    font-size: 14px;
}

.video-details-container .video-content .video-area {
    position: relative;
    margin-top: 30px;
}

.video-details-container .video-content .video-area .video-player {
    position: relative;
    width: 100%;
    height: 600px;
}

.video-details-container .video-content .video-area .encryption-masking {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    text-align: center;
    background-color: #000;
}

.video-details-container .video-content .video-area .encryption-masking .encryption-input {
    height: 36px;
    text-align: center;
}

.video-details-container .video-content .video-area .encryption-masking .encryptionVideo-btn {
    display: inline-block;
    width: 80px;
    height: 36px;
    line-height: 36px;
    color: #fff;
    background-color: #63b504;
    cursor: pointer;
}

.video-details-container .video-content .video-area .video-masking-gif {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    background: url('http://j.vzan.cc/dz/content/newhome/image/newhome/videoMasking.gif') no-repeat center;
    background-size: cover;
}

.video-details-container .video-content .video-area .flashErr {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    line-height: 100%;
    background: #000;
}


/* 社区团购 */
.grouping-banner {
    background: url('../../images/group/banner-bg.png') no-repeat;
    background-size: cover;
    overflow: hidden;
}

.grouping-banner-p1 {
    padding-top: 143px;
    font-size: 66px;
}

.grouping-banner-p2 {
    padding-top: 44px;
    font-size: 40px;
}

.grouping-btn-try {
    color: #FF943D;
}

.grouping-banner-img {
    height: 484px;
    width: 772px;
    right: -180px;
    top: 35px;
}

.grouping-hot {
    padding: 80px 0;
    height: 630px;
}

.grouping-hot-list {
    margin-top: 37px;
    margin-right: -40px;
}

.grouping-hot-listItem {
    width: 25%;
    float: left;
    padding-right: 40px;
}

.grouping-hot-listItem .inner {
    height: 356px;
    background: rgba(247, 247, 247, 1);
    border-radius: 4px;
    padding: 50px 0;
}

.grouping-hot-listItem .title {
    color: #FF943D;
    font-weight: normal;
}

.grouping-hot-listItem .logo {
    width: 142px;
    height: 142px;
    display: block;
    margin: 28px auto 14px auto;
}

.grouping-hot-listItem .desc {
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: #666;
}

.grouping-mode {
    height: 833px;
    background: url('../../images/group/mode-bg.png') no-repeat;
    background-size: cover;
    padding: 80px 0;
}

.grouping-mode-flow {
    display: block;
    width: 1200px;
    height: 560px;
    margin: 60px auto 0 auto;
}

.grouping-advantage {
    padding: 80px 0 40px 0;
}

.grouping-advantage-list {
    padding-top: 60px;
}

.grouping-advantage-listItem {
    padding: 0 32px;
    margin-bottom: 50px;
}

.grouping-advantage-listItem:last-child {
    margin-bottom: 0;
}

.grouping-advantage-listItem.big {
    padding-left: 0;
    padding-right: 0;
}

/* 可插拔 */
.typeList {
    width: 444px;
}

.typeList-item {
    margin-bottom: 30px;
}

.typeList-item.special {
    margin-bottom: 22.5px;
}

.typeList-title {
    font-size: 28px;
    text-align: center;
}

.typeList-title .img-wrapper-big {
    background: url('../../images/group/title-bg-big.png') no-repeat;
    background-size: cover;
    width: 188px;
}

.typeList-title .img-wrapper-nor {
    background: url('../../images/group/title-bg.png') no-repeat;
    background-size: cover;
    width: 158px;
}


.typeList-title .img-wrapper {
    display: inline-block;
    line-height: 35px;
    color: #fff;
    height: 44px;
}

.typeList .rank {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    background-color: #FF943D;
    font-size: 16px;
    color: #fff;
    display: inline-block;
    transform: rotate(45deg);
    line-height: 24px;
    vertical-align: middle;
    top: 50%;
    margin-top: -12px;
}

.typeList .rankNum {
    text-align: center;
    transform: rotate(-45deg);
}

.typeList .text {
    line-height: 33px;
    margin-left: 50px;
}

.typeList-desc {
    margin-bottom: 28px;
    color: #333;
}

.advantage-img-1 {
    width: 756px;
    display: block;
}

.advantage-img-top {
    position: absolute;
    width: 240px;
}

.advantage-img-top-2-2 {
    right: 0;
    top: 0;
}

.advantage-img {
    width: 670px;
    display: block;
}

.typeList-item-wrapper {
    width: 410px;
    margin-left: auto;
    margin-right: auto;
}

.grouping-support {
    padding: 60px 0 80px 0;
    height: 338px;
    background: url('../../images/group/support-bg.png') no-repeat;
    background-size: cover;
}

.grouping-support-listItem {
    width: 25%;
    float: left;
    line-height: 45px;
}

.grouping-support-listItem {
    line-height: 45px;
    font-size: 16px;
}

.grouping-support-listItem img {
    height: 45px;
    width: 45px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 18px;
}

.grouping-join {
    height: 593px;
    background: url('../../images/group/apply.png') no-repeat;
    background-size: cover;
    padding: 80px 0;
}

.grouping-join .form-group-code {
    width: 110%;
    margin-left: -17px;
}

#groupCodeBtn {
    right: 35px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #3D98FF;
}

/* 页面公用 */
.com-small-icon {
    width: 965px;
    margin: 40px auto 0 auto;
}

.com-small-icon .item {
    line-height: 45px;
    text-align: center;
    float: left;
}

.com-small-icon .item .img {
    height: 45px;
    width: 45px;
    display: inline-block;
    margin-right: 20px;
    vertical-align: top;
}


.com-small-icon .tudou-weixin {
    width: 160px;
    height: 160px;
    right: -180px;
    top: 50%;
    margin-top: -80px;
    display: none;
}

.com-small-icon .weixin:hover .tudou-weixin {
    display: block;
}