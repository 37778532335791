/**
 * Eric Meyer's Reset CSS v2.0 (http://meyerweb.com/eric/tools/css/reset/)
 * http://cssreset.com
 */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite, 
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    /* font-weight: normal; */
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
    font-family: -apple-system, BlinkMacSystemFont, 'Helvetica Neue', 'PingFang SC', 'Microsoft YaHei', 'Source Han Sans SC', 'Noto Sans CJK SC', 'WenQuanYi Micro Hei', sans-serif;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}


a {
    color: #000;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

img {
    border: none;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic;
}

input,
select,
textarea,
button {
    font-family: inherit;
    font-size: 100%;
}

textarea {
    overflow-y: auto;
    resize: none;
}

a,
input,
textarea {
    text-decoration: none;
    outline: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

area {
    outline: none;
}

ul,
li {
    list-style: none;
}

a {
    color: inherit;
}

input[type="text"],
input[type="submit"] {
    box-sizing: border-box;
    border: 0;
    -webkit-user-select: auto;
    -moz-user-select: auto;
    user-select: auto;
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: none;
    -webkit-touch-callout: none;
    -webkit-font-smoothing: antialiased;
}