.f {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex
}

.f1 {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    cursor: pointer
}

.fc {
    align-items: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center
}

.fc-h {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.f-end {
    -webkit-box-pack: justify;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
}

.fv {
    -webkit-box-orient: vertical;
    -webkit-flex-direction: column;
    flex-direction: column;
}

.fw {
    -webkit-flex-wrap: wrap;
    -webkit-box-lines: multiple;
    -moz-flex-wrap: wrap;
    flex-wrap: wrap;
}

.fj {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.fsa {
    -webkit-justify-content: space-around;
    justify-content: space-around;
}