body {
  font-size: 16px;
  background-color: #fff;
  color: #333;
}


/* 定位 */
.fl {
  float: left;
}

.fr {
  float: right;
}

.clear:after {
  display: block;
  overflow: hidden;
  clear: both;
  height: 0;
  visibility: hidden;
  content: "."
}


.clear {
  zoom: 1;
}

.clear:after,
.clear:before {
  display: table;
  content: '';
}

.clear:after {
  clear: both;
  overflow: hidden;
}


.dnone {
  display: none;
}

/* 字体色 */
.c63B504 {
  color: #63B504;
}

.c999 {
  color: #999 !important;
}

.c444 {
  color: #444;
}

.c666 {
  color: #666;
}

.c333 {
  color: #333 !important;
}

.cccc {
  color: #ccc !important;
}

.cfff {
  color: #fff !important;
}

.cE5E5E5 {
  color: #E5E5E5
}

.f-w-n {
  font-weight: normal;
}

/* 背景字体色  */
.theme-bg-color {
  background-color: #6FBA37;
}

.bg-w {
  background-color: #fff;
}

.theme-color {
  color: #6FBA37;
}

.color-blue {
  color: #6AA9FF;
}

.bg-light-black {
  background: rgba(67, 79, 98, 1);
}

.bg-green-gradient {
  background: linear-gradient(-30deg, rgba(73, 183, 89, 1), rgba(45, 167, 79, 1));
}

.bg-deep-gray {
  background-color: #434F62;
}

.bg-gray {
  background-color: #f7f7f7;
}


.bg-blue-linear {
  background: linear-gradient(-40deg,
    rgba(67, 100, 247, 0.8),
    rgba(111, 177, 252, 0.8));
}

.bg-orange-linear {
  background: linear-gradient(-40deg, rgba(243, 115, 53, 0.8), rgba(253, 200, 48, 0.8));
}

.bg-green-linear {
  background: linear-gradient(-40deg, rgba(17, 153, 142, 0.8), rgba(56, 239, 125, 0.8));
}

.bg-purple-linear {
  background: linear-gradient(-40deg, rgba(78, 84, 200, 0.8), rgba(143, 148, 251, 0.8));
}

.bg-light-blue-linear {
  background: linear-gradient(-40deg, rgba(91, 134, 229, 0.8), rgba(54, 209, 220, 0.8));
}

/* 字体位置 */
.tc {
  text-align: center;
}

.tr {
  text-align: right;
}

.vm {
  vertical-align: middle
}

/* 内外边距 */
.mt5 {
  margin-top: 5px;
}

.mt3 {
  margin-top: 3px;
}

.mt6 {
  margin-top: 6px;
}

.mt8 {
  margin-top: 8px;
}

.mt80 {
  margin-top: 80px;
}

.mt10 {
  margin-top: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml80 {
  margin-left: 80px;
}

.mt20 {
  margin-top: 20px;
}

.mt15 {
  margin-top: 15px;
}

.mt25 {
  margin-top: 25px;
}

.mt30 {
  margin-top: 30px;
}

.mt40 {
  margin-top: 40px;
}

.mt50 {
  margin-top: 50px;
}

.mt60 {
  margin-top: 60px;
}

.mt70 {
  margin-top: 70px;
}

.mt90 {
  margin-top: 90px;
}

.mt100 {
  margin-top: 100px;
}

.ml5 {
  margin-left: 5px;
}

.ml10 {
  margin-left: 10px;
}

.ml25 {
  margin-left: 25px;
}

.ml30 {
  margin-left: 30px;
}

.ml40 {
  margin-left: 40px;
}

.ml60 {
  margin-left: 60px;
}

.mr8 {
  margin-right: 8px;
}

.mr10 {
  margin-right: 10px;
}

.mr20 {
  margin-right: 20px;
}

.mr15 {
  margin-right: 15px;
}

.mr25 {
  margin-right: 25px;
}

.mr30 {
  margin-right: 30px;
}

.mr40 {
  margin-right: 40px;
}

.mr80 {
  margin-right: 80px;
}

.mh20 {
  margin: 20px 0;
}

.m30 {
  margin: 30px;
}

.mw10 {
  margin: 0 10px;
}

.mw5 {
  margin: 0 5px;
}

.mw15 {
  margin: 0 15px;
}

.mw5 {
  margin: 0 5px;
}

.mw25 {
  margin: 0 25px;
}

.mb37 {
  margin-bottom: 37px;
}

.mb70 {
  margin-bottom: 70px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb2 {
  margin-bottom: 2px;
}

.mb30 {
  margin-bottom: 30px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb15 {
  margin-bottom: 15px;
}

.mb16 {
  margin-bottom: 16px;
}

.mb10 {
  margin-bottom: 10px;
}

.p5 {
  padding: 5px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb40 {
  padding-bottom: 40px;
}

.pt60 {
  padding-top: 60px;
}

.pb80 {
  padding-bottom: 80px;
}

.p10 {
  padding: 10px;
}

.p20 {
  padding: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pl50 {
  padding-left: 50px;
}

.pr50 {
  padding-right: 50px;
}


.p30 {
  padding: 30px;
}

.pb80 {
  padding-bottom: 80px;
}

.pt80 {
  padding-top: 80px;
}

.p40 {
  padding: 40px;
}

.p0-10 {
  padding: 0 10px;
}

.pl8 {
  padding-left: 8px;
}

.pb10 {
  padding-bottom: 10px;
}

.pb30 {
  padding-bottom: 30px;
}

.pt10 {
  padding-top: 10px;
}

.pt5 {
  padding-top: 5px;
}

.pt30 {
  padding-top: 30px;
}

.pt20 {
  padding-top: 20px;
}

.pt80 {
  padding-top: 80px;
}

.pt100 {
  padding-top: 100px;
}

.pl10 {
  padding-left: 10px;
}

.pl20 {
  padding-left: 20px;
}

.pl30 {
  padding-left: 30px;
}

.pl25 {
  padding-left: 25px;
}

.pr0 {
  padding-right: 0;
}

.pr30 {
  padding-right: 30px;
}

.pr10 {
  padding-right: 10px;
}

.pr20 {
  padding-right: 20px;
}

.ph10 {
  padding: 10px 0
}

.ph20 {
  padding: 20px 0
}

.pw20 {
  padding: 0 20px;
}

.pw30 {
  padding: 0 30px;
}

.pt40 {
  padding-top: 40px;
}

.block-center {
  margin-left: auto;
  margin-right: auto;
}

/* 字体大小 */
.f12 {
  font-size: 12px;
}

.f16 {
  font-size: 16px;
}

.f15 {
  font-size: 15px;
}

.f18 {
  font-size: 18px;
}

.f14 {
  font-size: 14px;
}

.f19 {
  font-size: 19px;
}

.f20 {
  font-size: 20px;
}

.f22 {
  font-size: 22px;
}

.f24 {
  font-size: 24px;
}

.f26 {
  font-size: 26px;
}

.f28 {
  font-size: 28px;
}

.f30 {
  font-size: 30px;
}

.f32 {
  font-size: 32px;
}

.f34 {
  font-size: 34px;
}

.f36 {
  font-size: 36px;
}

.f38 {
  font-size: 38px;
}

.f40 {
  font-size: 40px;
}

.f42 {
  font-size: 42px;
}

.f44 {
  font-size: 44px;
}

.f47 {
  font-size: 47px;
}

.f48 {
  font-size: 48px;
}

.f50 {
  font-size: 50px;
}

.f57 {
  font-size: 57px;
}

.f60 {
  font-size: 60px;
}

.f66 {
  font-size: 66px;
}

.f70 {
  font-size: 70px;
}

.f80 {
  font-size: 80px;
}

.f90 {
  font-size: 90px;
}

.f100 {
  font-size: 100px;
}

/* 字体省略 */
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
  word-break: keep-all;
}

/* 模型大小 */
.circle-56 {
  height: 747px;
  width: 747px;
  border-radius: 50%;
}

/* 显示 */
.display-block {
  display: block;
}

.display-inline {
  display: inline;
}

.display-inline-block {
  display: inline-block;
}


/* 光标 */
.pointer {
  cursor: pointer;
}

/* 宽度 */
.w-full {
  width: 100%;
}



/*定位*/
.rel {
  position: relative;
}

.fixed {
  position: fixed;
}

.abs {
  position: absolute;
}

/* 间隙 */
.bb20 {
  border-bottom: 20px solid #f6f6f6;
}

.full {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.full-h {
  top: 0;
  bottom: 0;
}

.full-w {
  left: 0;
  right: 0;
}

.t-b {
  font-weight: bolder;
}

.btn-block {
  display: block;
  height: 1147px;
  line-height: 1147px;
  width: 7333px;
  border: none;
}


/* 行高 */
.lh14 {
  line-height: 1.4;
}

.lh15 {
  line-height: 1.5;
}

.lh13 {
  line-height: 1.3;
}

.lh18 {
  line-height: 1.8;
}

.lh2 {
  line-height: 2;
}

.lh16 {
  line-height: 1.6;
}

.lh2 {
  line-height: 2;
}

/* 宽度 */
.w150 {
  width: 150px;
}

.comWidth-center {
  width: 1200px;
  margin: 0 auto;
}

.of-h {
  overflow: hidden;
}

.w-50 {
  width: 50%;
}

.w-80 {
  width: 80%;
}

.x-y-c {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.btn-null:hover {
  background-color: #63B504;
  color: #fff;
  transition: background .25s ease-in-out;
}

.webkit-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.line2 {
  -webkit-line-clamp: 2;
}

.line4 {
  -webkit-line-clamp: 4;
}

/* 禁止选择文本 */
.u-s-n {
  webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* 垂直顶部对齐 */
.v-a-t {
  vertical-align: top;
}


/* 小三角 */

.triangle-down {
  border-top: 5px solid #999;
  border-bottom: 0 none;
}

.triangle-top {
  border-bottom: 5px solid #999;
  border-top: 0 none;
}

.triangle-y {
  border-left: 4px dashed transparent;
  border-right: 4px dashed transparent;
}

.triangle-x {
  border-bottom: 4px dashed transparent;
  border-top: 4px dashed transparent;
}

.triangle-right {
  border-left: 5px solid #999;
  border-right: 0 none;
}

.triangle-left {
  border-right: 5px solid #999;
  border-left: 0 none;
}

.triangle {
  width: 0;
  height: 0;
  padding: 0;
  font-size: 0;
  line-height: 0;
  /* overflow: hidden; */
  display: inline-block;
}

/* 小banner图 */
.small-banner {
  height: 200px;
}

/* 公用mask */
.com-mask {
  background-color: rgba(0, 0, 0, .4);
  z-index: 999;
  display: none;
}

.com-mask .article-img {
  width: 1000px;
  display: block;
  max-height: 800px;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(143, 148, 251, 0.8);
}

/* 联系我们弹窗 */
.contact-form {
  width: 552px;
  min-height: 552px;
  background: rgba(255, 255, 255, 1);
  border-radius: 8px;
  z-index: 1000;
  padding-top: 60px;
  left: 50%;
  top: 50%;
  margin-left: -226px;
  margin-top: -226px;
  display: none;
  transform-origin: left bottom;
  padding-bottom: 30px;
  border: 1px solid #ccc;
}

.contact-form .btn-close {
  height: 32px;
  width: 32px;
  right: 20px;
  top: 20px;
  position: absolute;
  cursor: pointer;
}


#userPopup {
  top: 40%;
}

.contact-form .sendCode {
  right: 0;
  top: 50%;
  transform: translateY(-100%);
  cursor: pointer;
}

#userPopup .xiaowei-contact-form,
#phonePopup .xiaowei-contact-form {
  width: 300px;
}

#userPopup .xiaowei-contact-form .form-group {
  margin-bottom: 10px;
}

.opacity0 {
  opacity: 0;
}

.hidden {
  display: none;
}

*[v-cloak] {
  display: none;
}

.toast {
  padding: 10px 20px;
  font-size: 14px;
  background-color: rgba(0, 0, 0, .8);
  border-radius: 4px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin-top: -17px;
  max-width: 400px;
  line-height: 1.5;
  color: #fff;
  z-index: 9999;
  word-wrap: break-word;
  transition: opacity .35s ease-in-out;
  opacity: 0;
}

.w80 {
  width: 80%;
}

.w33 {
  width: 33.33%;
}

.w50 {
  width: 50%;
}


/* 广告底部按钮 */
.adv-button {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  padding: 7px 18px;
  background-color: #63B504;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  color: #fff;
}

/* 百度垃圾商桥 */
body #newBridge {
  z-index: 100;
}

#newBridge .nb-icon-base .nb-icon-inner-wrap {
  visibility: hidden;
}

/* 导航栏 */
.navigator {
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
}

.navigator li {
  position: relative;
  width: 60px;
  height: 60px;
  background: rgba(45, 167, 79, 1);
  margin-bottom: 4px;
  transition: all .35s ease-in-out;
  cursor: pointer;
}

.navigator li:hover {
  background: #1d873a;
}

.navigator li:last-child {
  margin-bottom: 0;
}

.navigator li:hover .navigator-bubule {
  display: block;
}

.nav-goTop-wrap {
  opacity: 0;
  transition: opacity .8s ease;
}

#nav-goTop {
  background: url('../../images/navigator/nav-1.png') no-repeat;
  height: 15px;
  width: 26px;
}

#nav-phone {
  background: url('../../images/navigator/nav-2.png') no-repeat;
  height: 26px;
  width: 27px;
}

#nav-wxcode {
  background: url('../../images/navigator/nav-3.png') no-repeat;
  height: 26px;
  width: 26px;
}

#nav-service {
  background: url('../../images/navigator/nav-4.png') no-repeat;
  height: 28px;
  width: 28px;
}

#nav-wxcode~.navigator-bubule {
  left: -390%;
}

.navigator-bubule {
  padding: 20px 26px;
  left: -318%;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  border-radius: 4px;
  display: none;
}

.navigator-bubule img {
  display: block;
  width: 150px;
}

.arrow_box {
  border: 1px solid #d9d8d9;
}

.arrow_box:after,
.arrow_box:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.arrow_box:after {
  border-color: rgba(136, 183, 213, 0);
  border-width: 7px;
  margin-top: -7px;
  border-left-color: #fff;
}

.arrow_box:before {
  border-color: rgba(194, 225, 245, 0);
  border-left-color: #d9d8d9;
  border-width: 8px;
  margin-top: -8px;
}

/* 公用button */
.button {
  width: 147px;
  height: 50px;
  border-radius: 4px;
  line-height: 50px;
  margin-top: 54px;
  display: block;
  background-color: #fff;
}